.alice-carousel {
  overflow: hidden;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  font-size: 0;
  opacity: .4;
  z-index:100;
  
  span {
    background: #999;
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    width:100%;
    padding:14px 8px;
    align-items: center;
    box-sizing: border-box;

    &:before {
      content: "";
      color: white;
      display:block;
      width:6px;
      height:6px;
      border-top: 2px solid white;
      font-weight:600;
      line-height: 12px;
    }
  }
}

.alice-carousel__prev-btn {
  left: -50px;
  transition: all .2s;

  .alice-carousel:hover & {
    left:0;
  }
  
  span:before {
    border-left: 2px solid white;
    transform: translateX(2px) rotate(-45deg);
  }
}
.alice-carousel__next-btn {
  right: -50px;
  transition: all .2s;

  .alice-carousel:hover & {
    right:0;
  }

  span:before {
    border-right: 2px solid white;
    transform: translateX(-2px) rotate(45deg);
  }
}