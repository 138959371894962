.top-products {
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.productList {
  list-style-type: none;
  padding: 0;
}

.productListChip  {
  background-color: #16556E;
  color: #fff;
  font-weight: bold;
  padding: 5px 5px;
  font-size: 14px;
  margin-right: 10px;
}

.productItem {
  display: flex;
  margin-bottom: 10px;
}

.product-name {
  font-weight: bold;
}

.product-sales {
  color: #666;
}