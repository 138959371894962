.checklistCard {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0,0,0,.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.checklistCardQuestionRow {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;

  > *:first-child {
    margin-right: 1rem;
  }
}
.sectiontitle {
  font-size: 18px;
  font-weight: 600;
  max-width: 90%;
  margin-bottom: 5px;
}

.dragIcon {
  position: absolute;
  right: 15px;
  top: 40%;

  &:hover {
    cursor: move;
  }
}

.closeBtn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}