/**
 * These scss files are here to provide global useful styling
 * such as layouts and commonly repeated elements.
 *
 * There's more than like a scss module file for a component you're 
 * attempting to edit, check that first.
**/


@media screen and (min-width: 768px) {
  .content-sidebar {
    box-sizing: border-box;
    display: flex;
    
    &--content {
      box-sizing: border-box;
      flex: 0 0 75%;
      max-width: 75%;
      padding-right: 30px;
    }
    
    &--sidebar {
      box-sizing: border-box;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}



.tableLinks {
    display: block;
    text-align: right;
    width: 100%;
    
    &__inline {
      font-size: 16px;
      display: inline;
    }

    > *, &__inline > * {
        color: #777b7d;
        text-decoration: none;
        transition: color .2s;
        font-weight: 600;
        margin-right: 8px;
        cursor: pointer;
        user-select: none;

        &:last-child {
            margin-right: 0;
        }
        
        &:hover {
            color: #2f5e7b;
        }
    }

    .tableLink--disabled {

      color: #bfbfbf;
      &:hover {
        color: #bfbfbf;
      }
    }
}

.fixedWidth {
  display: inline-block;
  width: 100px;
}

.addMoreLink {
  text-align: right;
  margin-bottom: 12px;

  span {
    color: #2f5e7b;
    font-weight: 600;
    user-select: none;
    cursor: pointer;

    &:before {
      content: "+";
      margin-right: 6px;
    }
  }
}