.QaDetails {
  .name {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.OperatorDetails {
  .date {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .operator {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .pass {
    flex: 0 0 10%;
    max-width: 10%;
  }
}

.dateSelector {
  margin-bottom: 1.5rem;
  max-width: 320px;

  label {
    display: block;
    margin-bottom: .5rem;
  }
}

.muted {
  color: #929292;
  font-style: italic;
}

.detailContainer {
    background: #ffffff;
    padding: 1rem;
    border: 1px solid rgba(0,0,0,0.23);
    border-radius: 4px;
    margin-bottom: 1.5rem;
}

.passed {
    color: #4CAF50;
}

.qaDate {
    width: 100%;
    font-style: italic;
}