.bulletLine {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.bulletDesc {
  flex: 1 0 auto;
  //max-width: 70%;
  padding-left: 10px;
  padding-right: 10px;
}

.bulletType {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 10px;
  padding-right: 10px;
}

.bulletRemove {
  flex: 0 1 auto;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}