.loader {
  display: block;
  height:24px;
  width:100%;
  background-color: #F2F2F2;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(135deg, transparent 40%, rgba(255,255,255,0.8) 60%, transparent);
    :global {
      animation-name: loaderAnim;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

.largerLoader {
  margin-top: 11px;
  margin-bottom: 19px;
}

.loaderTitle {
  color: white;
  text-align: center;
  padding-top: 20px;
  font-size: 2rem;
  font-weight: 600;
}

.inlineLoader {
    @extend .loader;
    display: inline-block;
    max-width: 150px;
    vertical-align: middle;
    margin: 0 6px;
}

.fullSizeLoader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);

}

.fullSizeLoaderRing {
  color: white;
  margin: auto;
  font-size: 140px;
  * {
    font-size: 140px;
  }
}