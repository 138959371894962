.uploadWrap {
    display: flex;  
    flex-wrap: wrap;
    margin: 0 -10px;
}

.uploadItem {
    flex: 0 0 33%;
    justify-content: center;
    padding: 0 10px 20px;
    > * {
        margin: auto;
    }
}