.sectionTitleSection {
    
}

.productItem {
  display: flex;
  align-items: center;
  padding-bottom:1rem;
  margin-bottom:1rem; 
  background: #ffffff;
  border: 1px solid rgba(0,0,0,.23);
  padding: 1rem;
  border-radius: 4px;
}

.productItemImage {
  flex: 0 0 20%;
  max-width: 20%;
}

.productitemImageContainer {
  padding-top:75%;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left;
    max-width: 100%;
  }

}

.productItemDescription {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.dropzoneContainer {
  background: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.23);
}