@import url(https://fonts.googleapis.com/css?family=Titillium+Web&display=swap);
.Header_header__3MD_q {
  background: #2f5e7b;
}

.Header_headerContent__1IXrg {
  align-items: center;
  display: flex;
  margin: auto;
  max-width: 1280px;
  padding: 0.5rem 1rem;
}

.Header_headerMenu__3mwXH {
  display: flex;
  flex: 1 1 auto;
}

.Header_headerLogo__1pGRV {
  height: 40px;
  flex: 0 1 auto;
}

.Header_headerMenuLink__3a9uh {
  border-right: 1px solid #F8F8F8;
  color: #F8F8F8;
  display: block;
  padding: 0 10px;
  text-decoration: none;
}
.Header_headerMenuLink__3a9uh:last-child {
  border-right: none;
}

.Header_userPanel__2vWNe {
  flex: 0 1 auto;
}

.Header_icon__l_ACE {
  margin-right: 6px;
}
.Button_buttonAlignCenter__EgbRu {
  display: flex;
  justify-content: center;
}

.Button_buttonAlignRight__38da0 {
  display: flex;
  justify-content: flex-end;
}
.Main_main__2WjFy {
  padding: 1rem;
  max-width: 1280px;
  margin: auto;
}
.SectionHeader_sectionHeader__3Tje6 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.SectionHeader_sectionHeaderTitle__2DSrk {
  flex: 0 0 80%;
  max-width: 80%;
}

.SectionHeader_sectionHeaderActions__ZyoDx {
  flex: 0 0 20%;
  max-width: 20%;
}

.SectionHeader_sectionDescription__irfCn {
  flex: 0 0 100%;
  max-width: 100%;
}
.FormModal_modalTitle__1mP1T.FormModal_MuiDialogTitle-root__3FKge {
  padding-bottom: 8px;
}
.FormModal_modalTitle__1mP1T h2 {
  text-align: center;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.FormModal_modalContent__2RJQ0 {
  padding: 6px 24px 18px;
}

.FormModal_descriptionStyle__3enTN {
  margin-bottom: 20px;
}
.Input_InputStyle__2Z72v {
  margin-bottom: 25px;
  margin-top: 0;
}
.ImageUpload_uploadWrap__3Nx0L {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  width: 6rem;
}
.ImageUpload_uploadWrap__3Nx0L:hover .ImageUpload_removal__2By5L {
  top: -15px;
  right: -25px;
}

.ImageUpload_uploadLabel__24pUc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  height: 6rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.ImageUpload_uploadLabel__24pUc img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}
.ImageUpload_uploadLabel__24pUc * {
  flex: 0 0 100%;
  max-width: 100%;
  color: #333;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ImageUpload_uploadLabel__24pUc:hover * {
  color: #666;
}

.ImageUpload_removal__2By5L {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  text-align: center;
  width: 60px;
  height: 40px;
  top: -35px;
  right: -55px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  cursor: pointer;
}
.ImageUpload_removal__2By5L > * {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  width: 20px;
  fill: #A8A8A8;
}

.ImageUpload_unchangable__2g0cT {
  pointer-events: none;
}

.ImageUpload_previewLabel__1Rq7J {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ImageUpload_previewLabel__1Rq7J:after {
  content: "";
  background: transparent;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.ImageUpload_previewLabel__1Rq7J:hover:after {
  background: rgba(0, 0, 0, 0.25);
}

.ImageUpload_uploadContent__O4uA- {
  position: absolute;
  top: 18%;
  left: 0;
  width: 100%;
}

.ImageUpload_uploadIcon__3FApQ {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin: 0 auto;
}

.ImageUpload_uploadText__2NdKY {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6px;
  text-align: center;
  font-weight: 600;
}

.ImageUpload_fullWidth__35-Ih {
  width: 100%;
}
.ImageUpload_fullWidth__35-Ih .ImageUpload_uploadLabel__24pUc {
  padding-top: 100%;
}
.ImageUpload_fullWidth__35-Ih .ImageUpload_uploadContent__O4uA- {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ImageMultiUpload_uploadWrap__3KdtD {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.ImageMultiUpload_uploadItem__3Mgfo {
  flex: 0 0 33%;
  justify-content: center;
  padding: 0 10px 20px;
}
.ImageMultiUpload_uploadItem__3Mgfo > * {
  margin: auto;
}
.Loader_loader__2hZcM, .Loader_inlineLoader__22_fr {
  display: block;
  height: 24px;
  width: 100%;
  background-color: #F2F2F2;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.Loader_loader__2hZcM:after, .Loader_inlineLoader__22_fr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(135deg, transparent 40%, rgba(255, 255, 255, 0.8) 60%, transparent);
}
.Loader_loader__2hZcM:after, .Loader_inlineLoader__22_fr:after {
  -webkit-animation-name: loaderAnim;
          animation-name: loaderAnim;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.Loader_largerLoader__2Cczo {
  margin-top: 11px;
  margin-bottom: 19px;
}

.Loader_loaderTitle__yspq- {
  color: white;
  text-align: center;
  padding-top: 20px;
  font-size: 2rem;
  font-weight: 600;
}

.Loader_inlineLoader__22_fr {
  display: inline-block;
  max-width: 150px;
  vertical-align: middle;
  margin: 0 6px;
}

.Loader_fullSizeLoader__38A6K {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.Loader_fullSizeLoaderRing__1JpZA {
  color: white;
  margin: auto;
  font-size: 140px;
}
.Loader_fullSizeLoaderRing__1JpZA * {
  font-size: 140px;
}
.modalSectionHeader_modalSectionHeaderStyle__XMM9l {
  margin-bottom: 22px;
}
.ProductFolderSelector_productItem__3tByq {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.ProductFolderSelector_folderRow__2F0Z0 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ProductFolderSelector_folderSelector__2o9Ui {
  min-width: 140px;
}

.ProductFolderSelector_folderLabel__ypY6_ {
  margin-right: 10px;
}

.ProductFolderSelector_productItemImage__36nad {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductFolderSelector_productItemImage__36nad img {
  max-width: 100%;
}

.ProductFolderSelector_productItemDescription__3m20k {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.ProductFolderSelector_productItemActions__53P3q {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductFolderSelector_productItemActions__53P3q > * {
  margin-bottom: 12px;
}
.ProductFolderSelector_productItemActions__53P3q > *:last-child {
  margin-bottom: 0;
}
.ProductListitem_productItem__2DkTE {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.ProductListitem_productItemImage__3zRre {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductListitem_productItemImage__3zRre img {
  max-width: 100%;
}

.ProductListitem_productItemDescription__36ecx {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.ProductListitem_productItemActions__2qCoE {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductListitem_productItemActions__2qCoE > * {
  margin-bottom: 12px;
}
.ProductListitem_productItemActions__2qCoE > *:last-child {
  margin-bottom: 0;
}
.ProductsPagination_wrap__1hr2v {
  display: flex;
  justify-content: flex-end;
}
.ProductsLayout_filterRow__3MfmE {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductsLayout_filterRowSearch__278qP {
  max-width: 300px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.PaginatedTable_filters__32IPv {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.PaginatedTable_filterByCol__3VeMi {
  flex: 0 0 20%;
  margin-left: auto;
}

.PaginatedTable_filterByProduct__3KWDb {
  flex: 0 0 20%;
  margin-left: 25px;
  margin-right: auto;
}
.Checkbox_checkboxLabelStyle__2hqC6 {
  margin-bottom: 16px;
  margin-top: 0;
}

.Checkbox_checkboxStyle__CCfoM {
  padding: 4px 9px;
}
.Alert_warning__3H2g8 {
  background-color: #fdd9d7;
  border-radius: 6px;
  color: #7f231c;
  margin-bottom: 1rem;
  padding: 6px 12px;
}

.Alert_success__-MVQ4 {
  background-color: #dbefdc;
  border-radius: 6px;
  color: #285b2a;
  margin-bottom: 1rem;
  padding: 6px 12px;
}
.LoginForm_loginForm__2rOp2 {
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
}
.StatsCard_statsCard__LEe_N {
  margin: 10px;
}

.StatsCard_statsRow__3cHLK {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.TopProducts_top-products__336S4 {
  width: 300px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.TopProducts_productList__V23ui {
  list-style-type: none;
  padding: 0;
}

.TopProducts_productListChip__uYSFo {
  background-color: #16556E;
  color: #fff;
  font-weight: bold;
  padding: 5px 5px;
  font-size: 14px;
  margin-right: 10px;
}

.TopProducts_productItem__3lVZg {
  display: flex;
  margin-bottom: 10px;
}

.TopProducts_product-name__Nk0Hr {
  font-weight: bold;
}

.TopProducts_product-sales__RBXgp {
  color: #666;
}
.StatsLayout_statsRow__2rW6x {
  display: flex;
  flex-direction: row;
}

.StatsLayout_statsRow__2rW6x > * {
  flex: 1 1;
}
.ChecklistDropzone_dropzone__kLxrI, .ChecklistDropzone_eligibleDropzone__2jyVN {
  display: block;
  min-height: 550px;
  padding-bottom: 4rem;
  background: #efefef;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 4px;
  padding: 5px;
  position: relative;
  width: 100%;
}
.ChecklistDropzone_dropzone__kLxrI:after, .ChecklistDropzone_eligibleDropzone__2jyVN:after {
  content: "Drag and drop elements from the menu on the right hand side.";
  position: absolute;
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
  margin-left: -5px;
  margin-right: -5px;
  color: #9a9a9a;
  padding-top: 25px;
}

.ChecklistDropzone_eligibleDropzone__2jyVN {
  background: #F2F2F2;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.ChecklistDropzone_placeholder__1JbFh {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.ChecklistDropzone_placeholderTitle__q7T_b {
  font-size: 18px;
  font-weight: 600;
  max-width: 90%;
  margin-bottom: 5px;
}

.ChecklistDropzone_placeholderMeta__3gzhx {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.ChecklistDropzone_placeholderMeta__3gzhx > *:first-child {
  margin-right: 1rem;
}
.ChecklistItemCard_checklistCard__1mpRo {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem 0.5rem 1rem 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ChecklistItemCard_checklistCard__1mpRo h3 {
  padding: 0;
}

.ChecklistItemCard_closeBtn__8MXIz {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}

.ChecklistItemCard_largeIcon__3Qw9I {
  height: 1.75rem;
  width: 1.75rem;
}

.ChecklistItemCard_dragIcon___s-fa {
  position: absolute;
  right: 15px;
}
.ChecklistItemCard_dragIcon___s-fa:hover {
  cursor: move;
}

.ChecklistItemCard_checklistCardContent__3s-H7 {
  flex: 1 1 auto;
  padding: 0 1.5rem;
}

.ChecklistItemCard_checklistCardActions__1x-0S {
  flex: 0 0 2rem;
  background: green;
}
.ChecklistSectionCard_checklistCard__GhjEW {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.ChecklistSectionCard_checklistCardQuestionRow__3vLua {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.ChecklistSectionCard_checklistCardQuestionRow__3vLua > *:first-child {
  margin-right: 1rem;
}

.ChecklistSectionCard_sectiontitle__37SV_ {
  font-size: 18px;
  font-weight: 600;
  max-width: 90%;
  margin-bottom: 5px;
}

.ChecklistSectionCard_dragIcon__1JBMG {
  position: absolute;
  right: 15px;
  top: 40%;
}
.ChecklistSectionCard_dragIcon__1JBMG:hover {
  cursor: move;
}

.ChecklistSectionCard_closeBtn__3KNi3 {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.CardPool_cardPool__3Yi-e {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  max-height: 600px;
  overflow: auto;
}

.CardPool_placeholderCard__2Z3BO {
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.CardPool_placeholderCard__2Z3BO p {
  font-size: 20px;
}

.CardPool_cardPoolHeader__3Xz6W {
  padding: 1rem 1rem 0.5rem;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

.CardPool_cardPoolBody__2Hu0Z {
  padding: 1rem;
  background: #fdfdfd;
}

.CardPool_addNewButton__UIaRc {
  margin: 15px 0 15px auto;
}
.ChecklistLayout_productItem__4h2D0 {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 1rem;
  border-radius: 4px;
}

.ChecklistLayout_productItemImage__nzoKO {
  flex: 0 0 20%;
  max-width: 20%;
}

.ChecklistLayout_productitemImageContainer__1JQS9 {
  padding-top: 75%;
  position: relative;
}
.ChecklistLayout_productitemImageContainer__1JQS9 > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
  max-width: 100%;
}

.ChecklistLayout_productItemDescription__fJU1T {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.ChecklistLayout_dropzoneContainer__3uiZe {
  background: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.LabelledDetail_LabelledWrap__3Di9C {
  margin-right: 3.5rem;
}
.LabelledDetail_LabelledWrap__3Di9C h3 {
  padding: 0;
}

.LabelledDetail_underlined__3bfqC {
  position: relative;
  display: inline-block;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem !important;
}
.LabelledDetail_underlined__3bfqC:after {
  content: "";
  height: 2px;
  left: 0;
  width: 100%;
  max-width: 60px;
  background: #2f5e7c;
  position: absolute;
  bottom: 0;
}

.LabelledDetail_LabelledContent__1AjJB {
  font-size: 1.9rem;
}

.LabelledDetail_smallText__1NId9 {
  font-size: 1rem;
}
.QaChecklistDetails_qaSectionBox__3IGx- {
  margin-bottom: 24px;
}

.QaChecklistDetails_title__-ZOBx {
  font-size: 1.6rem;
  margin-bottom: 4px;
}

.QaChecklistDetails_items__12o-- {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.QaChecklistDetails_itemLine__2u1bJ {
  margin-bottom: 10px;
}
.QaChecklistDetails_itemLine__2u1bJ :first-child {
  margin-right: 6px;
}
.QaChecklistDetails_itemLine__2u1bJ * {
  vertical-align: middle;
}

.QaChecklistDetails_itemTag__RIHZ2 {
  font-style: bold;
}
.ImageLightbox_mediaLightbox__2PNXc img, .ImageLightbox_mediaLightbox__2PNXc video {
  max-width: 100%;
}

.ImageLightbox_downloadLink__y-ov6 {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  text-decoration: none;
  color: #ffffff;
  background: #2f5e7b;
  padding: 0.4rem 1rem;
  border-radius: 4px;
}
.ProductDatabaseDetailsLayout_QaDetails__1rDyV .ProductDatabaseDetailsLayout_name__2nDSc {
  flex: 0 0 25%;
  max-width: 25%;
}

.ProductDatabaseDetailsLayout_OperatorDetails__34LDs .ProductDatabaseDetailsLayout_date__2ILPE {
  flex: 0 0 25%;
  max-width: 25%;
}
.ProductDatabaseDetailsLayout_OperatorDetails__34LDs .ProductDatabaseDetailsLayout_operator__1JUHM {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductDatabaseDetailsLayout_OperatorDetails__34LDs .ProductDatabaseDetailsLayout_pass__1nW2r {
  flex: 0 0 10%;
  max-width: 10%;
}

.ProductDatabaseDetailsLayout_dateSelector__ScaK5 {
  margin-bottom: 1.5rem;
  max-width: 320px;
}
.ProductDatabaseDetailsLayout_dateSelector__ScaK5 label {
  display: block;
  margin-bottom: 0.5rem;
}

.ProductDatabaseDetailsLayout_muted__3iFeE {
  color: #929292;
  font-style: italic;
}

.ProductDatabaseDetailsLayout_detailContainer__3JoX2 {
  background: #ffffff;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.ProductDatabaseDetailsLayout_passed__1yUUg {
  color: #4CAF50;
}

.ProductDatabaseDetailsLayout_qaDate__2fbH8 {
  width: 100%;
  font-style: italic;
}
.Toggle_toggleWrap__39Ubj {
  max-width: 320px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}

.Toggle_toggleOption__tp1Wv, .Toggle_toggleOptionSelected__3RsWL {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
.Toggle_toggleOption__tp1Wv input, .Toggle_toggleOptionSelected__3RsWL input {
  display: none;
}

.Toggle_toggleOptionSelected__3RsWL {
  background: #2f5e7b;
  border-color: #2f5e7b;
  color: #F8F8F8;
}
.CriteriaWindow_criteriaWindow__3Q2g6 {
  padding: 1rem 0;
  display: flex;
}

.CriteriaWindow_criteriaWindowImage__2WqFe {
  flex: 0 0 6rem;
  max-width: 6rem;
  padding: 0.25rem;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.CriteriaWindow_criteriaWindowContent__6dc0P {
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
}

.CriteriaWindow_critMain__1vR6Q {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.CriteriaWindow_critText__15IJ1 {
  flex: 1 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.CriteriaWindow_critRemove__3_1q8 {
  flex: 0 1 auto;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.BulletPointLine_bulletLine__11X5I {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.BulletPointLine_bulletDesc__eVHjV {
  flex: 1 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.BulletPointLine_bulletType__1s3lq {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 10px;
  padding-right: 10px;
}

.BulletPointLine_bulletRemove__3u-7G {
  flex: 0 1 auto;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.CreateChecklistSectionLayout_sectionTitleSection__2JMQ9 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 1rem;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 1rem;
}

.CreateChecklistSectionLayout_sectionHeader__2ViOU {
  padding: 0 10px 0;
  flex: 0 0 100%;
  margin-bottom: 16px;
}

.CreateChecklistSectionLayout_sectionTitleInput__3gfDG {
  flex: 0 0 67%;
  max-width: 67%;
  padding: 0 10px;
  margin-bottom: 1rem;
}

.CreateChecklistSectionLayout_displayTitleInput__2TIRO {
  flex: 0 0 33%;
  max-width: 33%;
  padding: 0 10px;
  margin-bottom: 1rem;
}

.CreateChecklistSectionLayout_requireVideoInput__2F1GK {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
}
.ProductFolderListitem_productItem__14SMU {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.ProductFolderListitem_productItemImage__2d4ak {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductFolderListitem_productItemImage__2d4ak img {
  max-width: 100%;
}

.ProductFolderListitem_productItemDescription__2dh_R {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.ProductFolderListitem_productItemActions__3gim- {
  flex: 0 0 20%;
  max-width: 20%;
}
.ProductFolderListitem_productItemActions__3gim- > * {
  margin-bottom: 12px;
}
.ProductFolderListitem_productItemActions__3gim- > *:last-child {
  margin-bottom: 0;
}
body {
  margin: 0;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #fafafa;
}

* {
  box-sizing: border-box;
}

.alice-carousel {
  overflow: hidden;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  font-size: 0;
  opacity: 0.4;
  z-index: 100;
}
.alice-carousel__prev-btn span, .alice-carousel__next-btn span {
  background: #999;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 14px 8px;
  align-items: center;
  box-sizing: border-box;
}
.alice-carousel__prev-btn span:before, .alice-carousel__next-btn span:before {
  content: "";
  color: white;
  display: block;
  width: 6px;
  height: 6px;
  border-top: 2px solid white;
  font-weight: 600;
  line-height: 12px;
}

.alice-carousel__prev-btn {
  left: -50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.alice-carousel:hover .alice-carousel__prev-btn {
  left: 0;
}
.alice-carousel__prev-btn span:before {
  border-left: 2px solid white;
  -webkit-transform: translateX(2px) rotate(-45deg);
          transform: translateX(2px) rotate(-45deg);
}

.alice-carousel__next-btn {
  right: -50px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.alice-carousel:hover .alice-carousel__next-btn {
  right: 0;
}
.alice-carousel__next-btn span:before {
  border-right: 2px solid white;
  -webkit-transform: translateX(-2px) rotate(45deg);
          transform: translateX(-2px) rotate(45deg);
}

/**
 * These scss files are here to provide global useful styling
 * such as layouts and commonly repeated elements.
 *
 * There's more than like a scss module file for a component you're 
 * attempting to edit, check that first.
**/
@media screen and (min-width: 768px) {
  .content-sidebar {
    box-sizing: border-box;
    display: flex;
  }
  .content-sidebar--content {
    box-sizing: border-box;
    flex: 0 0 75%;
    max-width: 75%;
    padding-right: 30px;
  }
  .content-sidebar--sidebar {
    box-sizing: border-box;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.tableLinks {
  display: block;
  text-align: right;
  width: 100%;
}
.tableLinks__inline {
  font-size: 16px;
  display: inline;
}
.tableLinks > *, .tableLinks__inline > * {
  color: #777b7d;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  font-weight: 600;
  margin-right: 8px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tableLinks > *:last-child, .tableLinks__inline > *:last-child {
  margin-right: 0;
}
.tableLinks > *:hover, .tableLinks__inline > *:hover {
  color: #2f5e7b;
}
.tableLinks .tableLink--disabled {
  color: #bfbfbf;
}
.tableLinks .tableLink--disabled:hover {
  color: #bfbfbf;
}

.fixedWidth {
  display: inline-block;
  width: 100px;
}

.addMoreLink {
  text-align: right;
  margin-bottom: 12px;
}
.addMoreLink span {
  color: #2f5e7b;
  font-weight: 600;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.addMoreLink span:before {
  content: "+";
  margin-right: 6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3 {
  margin: 0;
  padding: 0 0 0.5rem;
}

h4, h5, h6 {
  margin: 0;
  padding: 0 0 0.25rem;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

h3 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 8px;
}

@-webkit-keyframes loaderAnim {
  0% {
    left: -100%;
  }
  66% {
    left: 200%;
  }
  100% {
    left: 200%;
  }
}

@keyframes loaderAnim {
  0% {
    left: -100%;
  }
  66% {
    left: 200%;
  }
  100% {
    left: 200%;
  }
}
