body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #fafafa;
}

* {
  box-sizing: border-box;
}

@import './assets/global-scss/vendor';
@import './assets/global-scss/layout';
@import './assets/global-scss/typography';
@import './assets/global-scss/animations';