.toggleWrap {
    max-width: 320px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
}

.toggleOption {
    flex: 0 0 50%;
    max-width: 50%;
    padding: .25rem .5rem;
    text-align: center;
    border: 1px solid rgba(0,0,0,.23);

    input {
        display: none;
    }
}

.toggleOptionSelected {
    @extend .toggleOption;
    background: #2f5e7b;
    border-color: #2f5e7b;
    color: #F8F8F8;
}