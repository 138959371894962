.checklistCard {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem .5rem 1rem 1.5rem;
  margin-bottom: 1rem;
  display: flex;  
  align-items: center;
  justify-content: space-between;

  h3 {
    padding: 0;
  }
}

.closeBtn {
  position: absolute;
  right: .5rem;
  top: .5rem;
  cursor: pointer;
}

.largeIcon {
    height: 1.75rem;
    width: 1.75rem;
}

.dragIcon {
  position: absolute;
  right: 15px;

  &:hover {
    cursor: move;
  }
}

.checklistCardContent {
  flex: 1 1 auto;
  padding: 0 1.5rem;
}

.checklistCardActions {
  flex: 0 0 2rem;
  background:green;
}

// .menuBars {
//   position: relative;
//   display:block;
//   height:4px;
//   width: 100%;
//   background: #444;
//   border-radius: 4px;
  
//   &:before, &:after {
//     content: "";
//     border-radius: 4px;
//     display:block;
//     height:4px;
//     width: 100%;
//     background: #444;
//     position: relative;
//   }

//   &:before {
//     top: -10px;
//   }
//   &:after {
//     bottom: -6px;
//   }

// }