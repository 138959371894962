.LabelledWrap {
  //margin-bottom: 1.5rem;
  margin-right: 3.5rem;

  h3 {
    padding: 0;
  }
}


.underlined {
  position: relative;
  display: inline-block;
  margin-bottom: .5rem;
  padding-bottom: .5rem !important;
  
  &:after {
    content: "";
    height: 2px;
    left: 0;
    width: 100%;
    max-width: 60px; 
    background: #2f5e7c;
    position: absolute;
    bottom: 0;
  }
}
.LabelledContent {
  font-size: 1.9rem;
}

.smallText {
  font-size: 1rem;
}