.modalTitle {
  &.MuiDialogTitle-root {
    padding-bottom: 8px;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }
}

.modalContent {
  padding: 6px 24px 18px;
}

.descriptionStyle {
  margin-bottom: 20px;
}