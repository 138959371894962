.qaSectionBox {
  margin-bottom: 24px;
}

.title {
  font-size: 1.6rem;
  margin-bottom: 4px;
}

.items {
  font-size: 1rem;
  // font-style: italic;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.itemLine {
  margin-bottom: 10px;
  :first-child {
    margin-right: 6px;
  }
  * {
    vertical-align: middle;
  }
}

.itemTag {
  font-style: bold;
}