.cardPool {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    position: sticky;
    top: 2rem;
    max-height: 600px;
    overflow: auto;
}

.placeholderCard {
  background-color: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;

  p {
    font-size: 20px;
  }
}

.cardPoolHeader {
  padding: 1rem 1rem .5rem;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  position: sticky;
  top: 0;
  z-index: 10;
}

.cardPoolBody {
  padding: 1rem;
  background: #fdfdfd;
}

.addNewButton {
  margin: 15px 0 15px auto;
}