.header {
  background: #2f5e7b;
}

.headerContent {
  align-items: center;
  display: flex;
  margin: auto;
  max-width: 1280px;
  padding: .5rem 1rem;
}

.headerMenu {
  display: flex;
  flex: 1 1 auto;
}

.headerLogo {
  height: 40px;
  flex: 0 1 auto;
}

.headerMenuLink {
  border-right: 1px solid #F8F8F8;
  color: #F8F8F8;
  display: block;
  padding: 0 10px;
  text-decoration: none;

  &:last-child {
    border-right: none;
  }
}

.userPanel {
  flex: 0 1 auto;
}

.icon {
  margin-right: 6px;
}