.buttonAlignCenter {
  display: flex;
  justify-content: center;
}

.buttonAlignRight {
  display: flex;
  justify-content: flex-end;
}

