.sectionTitleSection {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 1rem;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 1rem;
}

.sectionHeader {
    padding: 0 10px 0;
    flex: 0 0 100%;
    margin-bottom: 16px;
}

.sectionTitleInput {
    flex: 0 0 67%;
    max-width: 67%;
    padding: 0 10px;
    margin-bottom: 1rem;
}

.displayTitleInput {
    flex: 0 0 33%;
    max-width: 33%;
    padding: 0 10px;
    margin-bottom: 1rem;
}

.requireVideoInput {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 10px;
}