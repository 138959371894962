.productItem {
  display: flex;
  align-items: center;
  padding-bottom:1rem;
  margin-bottom:1rem;
  border-bottom: 2px solid;
  background: #ffffff;
  padding: 1rem;
  border: 1px solid rgba(0,0,0,.23);
  border-radius: 4px;
}

.folderRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.folderSelector {
  min-width: 140px;
}

.folderLabel {
  margin-right: 10px;
}

.productItemImage {
  flex: 0 0 20%;
  max-width: 20%;

  img {
    max-width: 100%;
  }
}

.productItemDescription {
  flex: 1 0 auto;
  padding: 0 1rem;
}

.productItemActions {
  flex: 0 0 20%;
  max-width: 20%;

  > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}