.filterRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterRowSearch {
    max-width: 300px;
    margin-bottom: 10px;
    margin-right: 10px;
}