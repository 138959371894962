@keyframes loaderAnim {
  0%   {
    left: -100%;
  }
  66% {
    left: 200%;   
  }
  100% {
    left: 200%;   
  }
}