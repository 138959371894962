.uploadWrap {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    width: 6rem;

    &:hover {  
        .removal {
        top: -15px;
        right: -25px;
        }
    }
}

.uploadLabel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    height: 6rem;
    user-select: none;
    cursor: pointer;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width:100%;
        object-fit: cover;
        object-position: center;
    }

    * {
      flex: 0 0 100%;
      max-width: 100%;
      color: #333;
      transition: all .2s;
    }

    &:hover {
      * {
        color: #666;
      }
    }
}

.removal {
    background: rgba(0,0,0,.7);
    position: absolute;
    text-align:center;
    width: 60px;
    height: 40px;
    top: -35px;
    right: -55px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);   
    transition: all .2s;
    z-index:100;
    cursor: pointer;
    

    > * {
        transform: rotate(135deg); 
        width: 20px;       
        fill: #A8A8A8;
    }
    
}
.unchangable {
  pointer-events: none;
}

.previewLabel {
    position: relative;    
    user-select:none;

    &:after {
        content: "";
        background: transparent;
        transition: background .2s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    &:hover {
        &:after {
            background: rgba(0, 0, 0, .25);
        }
    }
}

.uploadContent {
    position: absolute;
    top: 18%;
    left: 0;
    width: 100%;
}

.uploadIcon {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  margin: 0 auto;
}
.uploadText {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6px;
  text-align:center;
  font-weight:600;
}

.fullWidth {
    width: 100%;

    .uploadLabel {
        padding-top: 100%;
    }

    .uploadContent {
        top: 50%;
        transform: translateY(-50%);
    }
}