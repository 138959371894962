@import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3 {
  margin: 0;
  padding: 0 0 .5rem;
}
h4, h5, h6 {
  margin: 0;
  padding: 0 0 .25rem;
}

h1 { font-size: 2.6rem}
h2 { font-size: 2.4rem}
h3 { font-size: 2rem}
h3 { font-size: 1.4rem}

p {
  margin: 0 0 8px;
}