.criteriaWindow {
  //border-top: 1px solid rgba(0,0,0,.23);
  padding: 1rem 0;
  display: flex;
}

.criteriaWindowImage {
  flex: 0 0 6rem;
  max-width: 6rem;
  padding: 0.25rem;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.criteriaWindowContent {
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
}


.critMain {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.critText {
  flex: 1 0 auto;
  //max-width: 70%;
  padding-left: 10px;
  padding-right: 10px;
}

.critRemove {
  flex: 0 1 auto;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
}