.sectionHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.sectionHeaderTitle {
  flex: 0 0 80%;
  max-width: 80%;
}

.sectionHeaderActions {
  flex: 0 0 20%;
  max-width: 20%;
}

.sectionDescription {
  flex: 0 0 100%;
  max-width: 100%;
}