.warning {
  background-color: #fdd9d7;
  border-radius: 6px;
  color: #7f231c;
  margin-bottom: 1rem;
  padding: 6px 12px;
}

.success {
  background-color: #dbefdc;
  border-radius: 6px;
  color: #285b2a;
  margin-bottom: 1rem;
  padding: 6px 12px;
}