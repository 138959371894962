.dropzone {
  display: block;
  min-height:550px;
  padding-bottom: 4rem;
  background: #efefef;
  transition: all .2s;
  border-radius: 4px;
  padding: 5px;
  position: relative;
  width: 100%;

  &:after {
    content: "Drag and drop elements from the menu on the right hand side.";
    position: absolute;
    width: 100%;
    display: block;
    margin: auto;
    text-align: center;
    margin-left: -5px;
    margin-right: -5px;
    color: #9a9a9a;
    padding-top: 25px;
  }
}

.eligibleDropzone {
    @extend .dropzone;
    background: #F2F2F2;
    transition: all .2s;
}

.placeholder {
  position: relative;
  background-color: #f8f8f8;
  border: 1px solid rgba(0,0,0,.23);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.placeholderTitle {
  font-size: 18px;
  font-weight: 600;
  max-width: 90%;
  margin-bottom: 5px;
}

.placeholderMeta {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;

  > *:first-child {
    margin-right: 1rem;
  }
}