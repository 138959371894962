.mediaLightbox {
  img, video {
    max-width: 100%;
  }
}

.downloadLink {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  text-decoration: none;
  color: #ffffff;
  background: #2f5e7b;
  padding: .4rem 1rem;
  border-radius: 4px;
}